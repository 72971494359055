header {
  /* height: fit-content; */
  min-height: 900px;
  padding-top: 0rem;
}

h5 {
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1.3rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 1300px;
  height: 100vh;
  margin-top: 0rem;
}

.header-sub-container-1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 50%;
  width: 50%;
  margin-left: 5rem;
  margin-right: 5rem;
}

.header-animation {
  animation: header-text 400ms 400ms backwards;
}

@keyframes header-text {
  from {
    transform: translateX(-100vw);
  }
}

.header-btn-container {
  animation: cta-btn 400ms 400ms backwards;
}
/* Header btns animation */
@keyframes cta-btn {
  from {
    transform: translateY(100vh);
  }
}

.header-btn-container {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
}

.image {
  height: 500px;
  width: 900px;
}

.image-container {
  position: relative;
  right: 1000px;
  top: 190px;
  width: 100px;
  height: 100px;
  animation: image-container 400ms 600ms backwards;
}

@keyframes image-container {
  from {
    transform: scale(0) rotate(180deg);
  }
}

.browser-container {
  height: 500px;
  width: 100%;
  background-color: var(--yellow-color);
  margin-top: -20rem;
  border-radius: 2rem;
  border: 2px solid var(--secondary-dark-color);
  animation: browser-container-animation 400ms 400ms backwards;
}

@keyframes browser-container-animation {
  from {
    transform: translateX(100vh);
  }
}

.browser-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 10%;
  border-top-left-radius: 1.8rem;
  border-top-right-radius: 1.8rem;
  background-color: #f5a142;
  border-bottom: 2px solid var(--secondary-dark-color);
}

.navbar-circle {
  height: 20px;
  width: 20px;
  border-radius: 20rem;
  border: 1px solid var(--secondary-dark-color);
  background-color: white;
  margin-left: 0.7rem;
}

.navbar-circle:hover {
  cursor: pointer;
}

.brackets-container {
  display: flex;
  padding: 0;
  margin: 0;
}

.angle-brackets {
  color: var(--secondary-dark-color);
  font-size: 3rem;
  padding: 0;
  padding-left: 10px;
  margin: 0;
}

.code-lines-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: 0.15em;
  margin: 0 auto;
  margin-left: 2rem;
  animation: typing 1s steps(40, end);
  animation-delay: 530ms;
}

.text-2 {
  padding-left: 20px;
  color: var(--secondary-dark-color);
}

.text-3 {
  padding-left: 40px;
  color: var(--secondary-dark-color);
}

.text-4 {
  padding-left: 40px;
  color: var(--secondary-dark-color);
}

.text-5 {
  padding-left: 40px;
  color: var(--secondary-dark-color);
}

.text-6 {
  padding-left: 20px;
  margin-bottom: 30px;
  margin-left: 2rem;
  color: var(--secondary-dark-color);
}

.text-7 {
  padding-left: 20px;
  color: var(--secondary-dark-color);
}

.text-8 {
  padding-left: 40px;
  color: var(--secondary-dark-color);
}

.text-9 {
  padding-left: 40px;
  color: var(--secondary-dark-color);
}

.text-10 {
  padding-left: 20px;
  margin-left: 2rem;
  color: var(--secondary-dark-color);
}

.text-11 {
  border-right: 0.15em solid #fe5f3c;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 90%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #fe5f3c;
  }
}

@media (max-width: 1300px) {
  .header-container {
    flex-wrap: wrap;
    height: 1200px;
  }

  h1 {
    white-space: nowrap;
  }

  .header-sub-container-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25%;
    margin: 0;
  }

  .image-container {
    display: none;
  }
}

@media (max-width: 35em) {
  .header-container {
    display: flex;
    width: 100%;
    min-height: 800px;
    height: 100vh;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .header-sub-container-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h1 {
    font-size: 2rem;
  }

  h5 {
    font-size: 0.8rem;
  }

  .image-container {
    display: none;
  }

  .browser-container {
    margin-top: 5rem;
    margin-bottom: -10rem;
    height: 370px;
  }

  .navbar-circle {
    height: 10px;
    width: 10px;
  }

  .angle-brackets {
    font-size: 2rem;
  }

  .code-lines-container {
    margin: 0;
  }

  .text {
    font-size: 0.6rem;
    margin-left: 0.6rem;
  }

  .text-6 {
    font-size: 0.65rem;
    margin-left: 1rem;
  }

  .text-10 {
    font-size: 0.65rem;
    margin-left: 1rem;
  }
}
