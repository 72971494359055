.about {
  height: 100vh;
  position: relative;
}

.about-img-container {
  text-align: center;
  animation: about-image 200ms 0ms backwards;
}

.aboutImg {
  height: 400px;
  width: 600px;
}

.about-me-section {
  width: 100%;
  background-color: var(--secondary-dark-color);
  height: fit-content;
  padding: 3em;
  text-align: center;
  animation: about-me-section 200ms 0ms backwards;
}

.about-me-section h1 {
  animation: about-me-intro 300ms 100ms backwards;
}

.about-me-section p {
  padding: 2rem 20rem;
  font-size: 1rem;
  line-height: 2em;
  animation: about-me-p 300ms 100ms backwards;
}

.down-pointer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: var(--yellow-color);
  position: absolute;
  border-radius: 1000px;
  color: black;
  font-size: 2.5rem;
  left: 47.5%;

  animation: down-pointer 600ms 400ms backwards;
}

.about-container {
  display: flex;
  min-height: 800px;
  height: fit-content;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  padding: 5rem;
  gap: 6rem;
  animation: about-container 200ms 0ms backwards;
}

.description-boxes {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: fit-content;
  padding: 2rem;
  background-color: var(--secondary-dark-color);
  color: var(--main-color);
  border-radius: 1rem;
  box-shadow: 0px 0px 18px var(--secondary-dark-color);
  animation: description-boxes 300ms 200ms backwards;
  transition: transform 800ms cubic-bezier(0.05, 0.43, 0.25, 0.95);
}

.description-boxes:nth-child(1) {
  transform: translateX(-30%) rotate(-5deg);
}

.description-boxes:nth-child(3) {
  transform: translateX(30%) rotate(5deg);
}

.about-container:hover > .description-boxes:nth-child(1) {
  transform: translate(-100%, 5%) rotate(-10deg);
}

.about-container:hover > .description-boxes:nth-child(3) {
  transform: translate(100%, 5%) rotate(10deg);
}

.icon {
  font-size: 5rem;
  margin-bottom: 1.5rem;
}

.about-description {
  margin-top: 2rem;
  text-align: center;
}

p {
  margin-top: 0.5rem;
  color: white;
  text-align: center;
}

.about-contact-section {
  height: 15rem;
  padding: 3rem;
  text-align: center;
  background-color: var(transparent);
}

.about-btn {
  width: max-content;
  display: inline-block;
  background-color: var(--secondary-dark-color);
  color: var(white);
  padding: 0.7rem 2rem 1rem 2rem;
  border-radius: 3em;
  cursor: pointer;
  transition: 200ms;
}

.about-btn:hover {
  background-color: var(--yellow-color);
  color: var(--secondary-dark-color);
  transform: scale(1.05);
}

.about-btn span:first-child {
  margin-right: 5px;
  font-size: 1.1rem;
}

.about-btn span:last-child {
  vertical-align: top;
  font-size: 1rem;
}

@media (max-width: 1200px) {
  .about-me-section p {
    padding: 2rem 8rem;
  }

  .about-container {
    min-height: 850px;
    padding-top: 10rem;
    padding-bottom: 10rem;
    gap: 3rem;
  }

  .description-boxes {
    width: 300px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 900px) {
  .about-me-section h1 {
    font-size: xx-large;
  }

  .down-pointer {
    left: 45%;
  }
}

@media (max-width: 35em) {
  .about {
    height: fit-content;
    position: relative;
  }

  .down-pointer {
    height: 60px;
    width: 60px;
    font-size: 2rem;
    left: 43%;
    margin-top: 1.6rem;
  }

  .aboutImg {
    height: 200px;
    width: 300px;
  }

  .about-me-section {
    height: fit-content;
    font-size: 1rem;
  }

  .about-container {
    flex-direction: column;
    transform: none;
    padding: 0;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .description-boxes {
    position: relative;
    width: 300px;
  }

  .description-boxes:nth-child(1) {
    transform: none;
  }

  .description-boxes:nth-child(3) {
    transform: none;
  }

  .about-container:hover > .description-boxes:nth-child(1) {
    transform: none;
  }

  .about-container:hover > .description-boxes:nth-child(3) {
    transform: none;
  }

  .about-me-section h1 {
    font-size: 1.1rem;
  }

  .about-me-section p {
    padding: 0;
    font-size: 1rem;
    line-height: 2em;
  }
  .about-contact-section {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
}

@keyframes about-image {
  from {
    transform: scale(0);
  }
}

@keyframes about-me-section {
  from {
    transform: translateX(-100vh);
  }
}

@keyframes about-me-intro {
  from {
    transform: translateX(100vh);
  }
}

@keyframes about-me-p {
  from {
    transform: translateX(-100vh);
  }
}

@keyframes about-container {
  from {
    transform: scale(0);
  }
}

@keyframes description-boxes {
  from {
    transform: translateY(100vh);
  }
}

@keyframes down-pointer {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20px);
  }
}
