.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 10rem;
}

.contact-img-container {
  height: 200px;
  width: 200px;
  animation: contact-image-animation 300ms backwards;
}

@keyframes contact-image-animation {
  from {
    transform: scale(0) rotate(180deg);
  }
}

.contact-img-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.contact-title {
  width: 100%;
  height: fit-content;
  word-wrap: break-word;
  padding-left: 15rem;
  padding-right: 15rem;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  animation: contact-image-animation 300ms backwards;
}

.contact-arrow-down {
  color: transparent;
  background-color: transparent;
}

.contact-input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
}

.contact-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  background-color: var(--secondary-dark-color);
  height: 650px;
  padding: 4rem;
  animation: contact-section-animation 300ms 600ms backwards;
}

@keyframes contact-section-animation {
  from {
    transform: translateX(-100%);
  }
}

.contact-option {
  background-color: var(--main-color);
  padding: 3rem;
  border-radius: 0.5rem;
  text-align: center;
  border: 1px solid transparent;
  transition: 200ms;
}

.contact-option:hover {
  background-color: transparent;
  border-color: var(--main-color);
  color: var(--main-color);
}

.contact-option a {
  padding: 0.5em 1em;
  border-radius: 1.5em;
  background-color: var(--yellow-color);
  color: var(--secondary-dark-color);
}

.contact-option a:hover {
  background-color: var(--main-color);
  transition: 200ms;
  color: white;
}

.contact-img {
  font-size: 2rem;
  margin-bottom: 0.6rem;
  margin-top: -1rem;
}

.left-section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
}

.right-section {
  height: 100%;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 2px solid var(--main-color);
  resize: none;
  color: white;
}

input:focus {
  box-shadow: 0 0 10px var(--main-color);
}

textarea:focus {
  box-shadow: 0 0 10px var(--main-color);
}

::placeholder {
  color: white;
}

.contact-btn {
  width: fit-content;
  cursor: pointer;
  border: 1px solid var(--main-color);
  background-color: var(--secondary-dark-color);
  color: var(--main-color);
  padding: 1em 2em;
  border-radius: 3em;
}

.contact-btn:hover {
  background-color: var(--main-color);
  transition: 200ms;
  color: black;
}

@media (max-width: 1300px) {
  .contact-title {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .contact-sections {
    gap: 3rem;
  }
}

@media only screen and (min-width: 750px) and (max-width: 900px) {
  .contact-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;
  }

  .left-section {
    flex-direction: row;
  }

  .contact-option {
    width: 350px;
  }

  .contact-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 750px;
    margin-bottom: 2rem;
  }
}

@media (max-width: 35em) {
  .contact-container {
    width: 100%;
  }

  .contact-img-container {
    height: 150px;
    width: 150px;
  }

  .contact-title {
    width: 350px;
    height: fit-content;
    margin: 0;
    padding: 1rem;
    text-align: center;
  }

  .contact-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 0;
    padding-top: 5rem;
  }

  .left-section {
    width: 280px;
  }

  .contact-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 280px;
    margin-bottom: 2rem;
  }
}
