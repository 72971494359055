@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

body {
  height: fit-content;

  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
}

:root {
  --main-color: #fe5f3c;
  --main-color-light: #fe723c;
  --secondary-dark-color: #272a31;
  --yellow-color: #ffc600;
  --shade-of-dark-color: #22242a;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: var(--main-color);
  color: #fff;

  /*overflow: hidden;*/
}

section {
  margin-top: 8rem;
  height: 100vh;
}

.btn {
  width: max-content;
  display: inline-block;
  background-color: var(--secondary-dark-color);
  color: var(--main-color);
  padding: 1em 2em;
  border-radius: 3em;
  cursor: pointer;
  transition: 200ms;
  border: 1px solid transparent;
}

.btn-secondary {
  background-color: var(--yellow-color);
  color: var(--secondary-dark-color);
}

.btn:hover {
  background-color: transparent;
  border: 1px solid var(--secondary-dark-color);
  color: var(--secondary-dark-color);
}

.btn-secondary:hover {
  background-color: transparent;
  color: var(--secondary-dark-color);
}
